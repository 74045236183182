.cms-page-view:not([class*="pagebuilder"]) {
  .page-title-wrapper {
    margin-top: $cms-page-title-mt;

    @include respond-to-up(md) {
      margin-top: $cms-page-title-mt-md;
    }
  }

  .column.main {
    margin-top: $cms-page-col-main-mt;
    margin-bottom: $cms-page-col-main-mb;

    @include respond-to-up(md) {
      margin-bottom: $cms-page-col-main-mb-md;
    }

    ul:not(.tabs-navigation) {
      list-style: disc;
    }

    ol:not(.product-items) {
      list-style: decimal;
    }

    ul:not(.tabs-navigation),
    ol:not(.product-items) {
      padding: $cms-page-list-padding;
    }

    p {
      @include respond-to-up(md) {
        margin-bottom: $cms-page-p-mb-md;
      }
    }

    p,
    ul:not(.tabs-navigation),
    ol:not(.product-items) {
      + h2,
      + h3,
      + h4,
      + h5,
      + h6 {
        @include respond-to-up(md) {
          padding-top: $cms-page-heading-pt-md;
        }
      }
    }

    h2 {
      margin-bottom: $cms-page-h2-mb;
    }

    h3 {
      margin-bottom: $cms-page-h3-mb;
    }

    h4 {
      margin-bottom: $cms-page-h4-mb;
    }

    h5 {
      margin-bottom: $cms-page-h5-mb;
    }

    h6 {
      margin-bottom: $cms-page-h6-mb;
    }

    hr {
      margin-bottom: spacers(3);

      @include respond-to-up(md) {
        margin-top: spacers(3);
        margin-bottom: spacers(4);
      }

      @include respond-to-up(lg) {
        margin-top: spacers(4);
        margin-bottom: spacers(5);
      }
    }

    p > a {
      text-decoration: underline;
    }

    table {
      table-layout: fixed;
      padding: $cms-page-table-padding;
    }

    th {
      background: $gray-100;
    }

    th,
    td {
      padding: $cms-page-th-td-padding;
      border: $cms-page-table-border;
    }

    blockquote {
      display: block;
      position: relative;
      width: 100%;
      font-size: $cms-blockquote-font-size;
      margin: $cms-blockquote-margin;
      padding: $cms-blockquote-padding;
      border-left: $cms-blockquote-border;
      line-height: $cms-blockquote-line-height;
      background: $cms-blockquote-background;

      @include respond-to-up(md) {
        width: $cms-blockquote-width-md;
      }

      span,
      p {
        font-style: italic;
      }

      p {
        margin-bottom: 0;
      }

      &:before {
        font-family: Arial;
        content: "\201C";
        color: $cms-blockquote-quote-color;
        font-size: $cms-blockquote-quote-size;
        position: absolute;
        left: 10px;
        top: -10px;
      }

      &:after {
        content: '';
      }

      span.cite {
        display: block;
        color: $cms-blockquote-cite-color;
        font-weight: bold;
        text-align: right;
        margin-top: $cms-blockquote-cite-mt;
      }
    }
  }
}
