/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/**
 * Loader to be used across the site
 * Use this variable
 */
/**
 * Form Elements config
 */
/**
 * Collection of generic/global variables used across the theme
 */
.cms-page-view:not([class*="pagebuilder"]) .page-title-wrapper {
  margin-top: 1rem;
}

@media screen and (min-width: 768px) {
  .cms-page-view:not([class*="pagebuilder"]) .page-title-wrapper {
    margin-top: 1.5rem;
  }
}

.cms-page-view:not([class*="pagebuilder"]) .column.main {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

@media screen and (min-width: 768px) {
  .cms-page-view:not([class*="pagebuilder"]) .column.main {
    margin-bottom: 3rem;
  }
}

.cms-page-view:not([class*="pagebuilder"]) .column.main ul:not(.tabs-navigation) {
  list-style: disc;
}

.cms-page-view:not([class*="pagebuilder"]) .column.main ol:not(.product-items) {
  list-style: decimal;
}

.cms-page-view:not([class*="pagebuilder"]) .column.main ul:not(.tabs-navigation),
.cms-page-view:not([class*="pagebuilder"]) .column.main ol:not(.product-items) {
  padding: 0 1.5rem 1rem 1.5rem;
}

@media screen and (min-width: 768px) {
  .cms-page-view:not([class*="pagebuilder"]) .column.main p {
    margin-bottom: 1.5rem;
  }
}

@media screen and (min-width: 768px) {
  .cms-page-view:not([class*="pagebuilder"]) .column.main p + h2,
  .cms-page-view:not([class*="pagebuilder"]) .column.main p + h3,
  .cms-page-view:not([class*="pagebuilder"]) .column.main p + h4,
  .cms-page-view:not([class*="pagebuilder"]) .column.main p + h5,
  .cms-page-view:not([class*="pagebuilder"]) .column.main p + h6,
  .cms-page-view:not([class*="pagebuilder"]) .column.main ul:not(.tabs-navigation) + h2,
  .cms-page-view:not([class*="pagebuilder"]) .column.main ul:not(.tabs-navigation) + h3,
  .cms-page-view:not([class*="pagebuilder"]) .column.main ul:not(.tabs-navigation) + h4,
  .cms-page-view:not([class*="pagebuilder"]) .column.main ul:not(.tabs-navigation) + h5,
  .cms-page-view:not([class*="pagebuilder"]) .column.main ul:not(.tabs-navigation) + h6,
  .cms-page-view:not([class*="pagebuilder"]) .column.main ol:not(.product-items) + h2,
  .cms-page-view:not([class*="pagebuilder"]) .column.main ol:not(.product-items) + h3,
  .cms-page-view:not([class*="pagebuilder"]) .column.main ol:not(.product-items) + h4,
  .cms-page-view:not([class*="pagebuilder"]) .column.main ol:not(.product-items) + h5,
  .cms-page-view:not([class*="pagebuilder"]) .column.main ol:not(.product-items) + h6 {
    padding-top: 1rem;
  }
}

.cms-page-view:not([class*="pagebuilder"]) .column.main h2 {
  margin-bottom: 3rem;
}

.cms-page-view:not([class*="pagebuilder"]) .column.main h3 {
  margin-bottom: 1.5rem;
}

.cms-page-view:not([class*="pagebuilder"]) .column.main h4 {
  margin-bottom: 1rem;
}

.cms-page-view:not([class*="pagebuilder"]) .column.main h5 {
  margin-bottom: 1rem;
}

.cms-page-view:not([class*="pagebuilder"]) .column.main h6 {
  margin-bottom: 0.5rem;
}

.cms-page-view:not([class*="pagebuilder"]) .column.main hr {
  margin-bottom: 1rem;
}

@media screen and (min-width: 768px) {
  .cms-page-view:not([class*="pagebuilder"]) .column.main hr {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }
}

@media screen and (min-width: 992px) {
  .cms-page-view:not([class*="pagebuilder"]) .column.main hr {
    margin-top: 1.5rem;
    margin-bottom: 3rem;
  }
}

.cms-page-view:not([class*="pagebuilder"]) .column.main p > a {
  text-decoration: underline;
}

.cms-page-view:not([class*="pagebuilder"]) .column.main table {
  table-layout: fixed;
  padding: 0.5rem;
}

.cms-page-view:not([class*="pagebuilder"]) .column.main th {
  background: #f8f9fa;
}

.cms-page-view:not([class*="pagebuilder"]) .column.main th,
.cms-page-view:not([class*="pagebuilder"]) .column.main td {
  padding: 0.5rem;
  border: 1px solid #e9ecef;
}

.cms-page-view:not([class*="pagebuilder"]) .column.main blockquote {
  display: block;
  position: relative;
  width: 100%;
  font-size: 1.25rem;
  margin: 1.5rem auto;
  padding: 1rem 2rem 1rem 3rem;
  border-left: 5px solid #b0aa00;
  line-height: 1.6;
  background: #f8f9fa;
}

@media screen and (min-width: 768px) {
  .cms-page-view:not([class*="pagebuilder"]) .column.main blockquote {
    width: 80%;
  }
}

.cms-page-view:not([class*="pagebuilder"]) .column.main blockquote span,
.cms-page-view:not([class*="pagebuilder"]) .column.main blockquote p {
  font-style: italic;
}

.cms-page-view:not([class*="pagebuilder"]) .column.main blockquote p {
  margin-bottom: 0;
}

.cms-page-view:not([class*="pagebuilder"]) .column.main blockquote:before {
  font-family: Arial;
  content: "\201C";
  color: #b0aa00;
  font-size: 2.5rem;
  position: absolute;
  left: 10px;
  top: -10px;
}

.cms-page-view:not([class*="pagebuilder"]) .column.main blockquote:after {
  content: '';
}

.cms-page-view:not([class*="pagebuilder"]) .column.main blockquote span.cite {
  display: block;
  color: #b0aa00;
  font-weight: bold;
  text-align: right;
  margin-top: 1rem;
}
